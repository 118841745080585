<template>
  <div
    style="
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    "
  >
    <BaseHeader title="Gestão de Campanhas"> </BaseHeader>
    <b-tabs
      :class="{ 'mt-4': !isMobile }"
      nav-class="TabHeader no-border"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <b-tab :title="`Métricas`" active>
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
          <div style="display: flex; justify-content: center">
            <BaseButton variant="info" style="width:100%;" @click="routeListCampanha">
              Ver Campanhas
            </BaseButton>
            <!-- <BaseButton variant="primary" @click="routeCreateCamapanha">
              Criar Campanha
            </BaseButton> -->
          </div>

          <div v-if="isMobile">
            <div class="container-graficos">
              <div class="card-content-mobile">
                <div class="card-and-title-mobile">
                  <div class="card-number-mobile">1</div>
                  <span>Acessos</span>
                </div>
                <div class="graph-number-mobile">
                  <span>123.24% (2.461.104)</span>
                </div>
              </div>
              <div class="seta1-mobile"><span>72%</span></div>
              <div class="card-content-mobile">
                <div class="card-and-title-mobile">
                  <div class="card-number-mobile">2</div>
                  <span>Leads</span>
                </div>
                <div class="graph-number-mobile">
                  <span>112.09% (1.771.994)</span>
                </div>
              </div>
              <div class="seta2-mobile"><span>56%</span></div>
              <div class="card-content-mobile">
                <div class="card-and-title-mobile">
                  <div class="card-number-mobile">3</div>
                  <span>Cadastros</span>
                </div>
                <div class="graph-number-mobile">
                  <span>104.54% (992.317) </span>
                </div>
              </div>
              <div class="seta3-mobile"><span>68%</span></div>
              <div class="card-content-mobile">
                <div class="card-and-title-mobile">
                  <div class="card-number-mobile">4</div>
                  <span>Vendas</span>
                </div>
                <div class="graph-number-mobile">
                  <span>134.57% (674.775)</span>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-tab>
      <b-tab :title="`Páginas`" v-if="!isMobile">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
        </b-container>
      </b-tab>

      <b-tab :title="`Formulários`">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
        </b-container>
      </b-tab>
      <b-tab :title="`Automação`">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="!loading"
        >
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";

export default {
  components: {
    BaseHeader,
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      client: {
        width: 0,
      },
      teste: "",
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment(),
      },
    };
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2016, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    routeListCampanha() {
      this.$router.push({ name: "CampanhasLista" });
    },
    routeCreateCamapanha() {
      this.$router.push({ name: "CampanhasCriar" });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.seta3-mobile {
  position: absolute;
  height: 40px;
  width: 50px;
  background-color: #012c23;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 10px 10px;
  top: 395px;
  left: 130px;
  z-index: 3;
  span {
    color: #ffffff !important;
    font-weight: 450 !important;
    font-size: 16px !important;
  }
  &::after {
    position: absolute;
    right: 15px;
    bottom: -29px;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 21px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #012c23;
    transform: rotate(90deg);
  }
}
.seta2-mobile {
  position: absolute;
  height: 40px;
  width: 50px;
  background-color: #012c23;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 10px 10px;
  top: 255px;
  left: 130px;
  z-index: 3;
  span {
    color: #ffffff !important;
    font-weight: 450 !important;
    font-size: 16px !important;
  }
  &::after {
    position: absolute;
    right: 15px;
    bottom: -29px;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 21px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #012c23;
    transform: rotate(90deg);
  }
}
.seta1-mobile {
  position: absolute;
  height: 40px;
  width: 50px;
  background-color: #012c23;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 10px 10px;
  top: 115px;
  left: 130px;
  z-index: 3;
  span {
    color: #ffffff !important;
    font-weight: 450 !important;
    font-size: 16px !important;
  }
  &::after {
    position: absolute;
    right: 15px;
    bottom: -29px;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 21px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #012c23;
    transform: rotate(90deg);
  }
}
.graph-number-mobile {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 0 0 0;
  span {
    color: #012c23 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
}
.card-content-mobile {
  position: relative;
  background-color: #ffffff;
  border: 1.5px solid #eeeeee;
  box-shadow: 0px 4px 10px 2px rgba(1, 41, 37, 0.116);
  border-radius: 10px;
}
.card-number-mobile {
  background-color: var(--greenn);
  box-shadow: 0px 2px 5px 2px rgba(1, 41, 37, 0.116);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 5px 11px 5px 11px;
}
.card-and-title-mobile {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #abacad67;
  span {
    color: #012c23 !important;
    font-weight: 550 !important;
    font-size: 16px !important;
  }
}
.card-content-mobile {
  padding: 20px;
}
.container-graficos {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.seta1 {
  position: absolute;
  height: 2vw;
  width: 4.2vw;
  background-color: #012c23;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 3;
  border-radius: 5px 5px 5px 5px;
  span {
    color: #ffffff !important;
    font-weight: 450 !important;
    font-size: 1vw !important;
  }
}

.seta1::after {
  position: absolute;
  right: -0.8vw;
  content: "";
  width: 0;
  height: 0;
  border-top: 1.035vw solid transparent;
  border-bottom: 1.035vw solid transparent;
  border-left: 1vw solid #012c23;
}

.new-graphic {
  width: 100%;
  height: 15vw;
  position: relative;
}

.card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 12%;
}

.card-and-title {
  width: 100%;
  display: flex;
  gap: 1vw;
  align-items: center;
  padding-bottom: 0.8vw;
  border-bottom: 1px solid #abacad67;
  .card-title {
    margin: 0;
    padding: 0;
    span {
      color: #012c23 !important;
      font-weight: 550 !important;
      font-size: 1vw !important;
    }
  }
}

.graph-number {
  width: 100%;
  height: calc(100% - 3.2vw);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  span {
    color: #012c23 !important;
    font-weight: 500 !important;
    font-size: 1.1vw !important;
  }
}

.card-number {
  // background: linear-gradient(305deg, #025847 0%, rgba(9,121,101,1) 35%, rgb(6, 199, 128) 100%);
  background-color: var(--greenn);
  box-shadow: 0px 2px 5px 2px rgba(1, 41, 37, 0.116);
  width: 2.4vw;
  height: 2.4vw;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white !important;
    font-weight: 650 !important;
    font-size: 1.1vw !important;
  }
}

.graph-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4vw;
}
.card-graph {
  position: relative;
  background-color: #ffffff;
  border: 1.5px solid #eeeeee;
  box-shadow: 0px 4px 10px 2px rgba(1, 41, 37, 0.116);
  width: 21vw;
  min-width: 21vw;
  height: 11vw;
  // border-radius: 2vw 0;
  border-radius: 10px;
}

.graph-line {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.repeat-one {
  width: 100%;
  height: 8px;
  background-image: repeating-linear-gradient(
    45deg,
    var(--greenn)00,
    var(--greenn)00 10px,
    var(--greenn) 10px,
    var(--greenn) 20px
  );
}

.repeat-two {
  width: 100%;
  height: 8px;
  background-image: repeating-linear-gradient(
    135deg,
    var(--greenn)00,
    var(--greenn)00 10px,
    var(--greenn) 10px,
    var(--greenn) 20px
  );
}
.title-graph {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.flex-graph {
  display: flex;
  width: 95%;
  justify-content: space-between;
  position: relative;
  padding-top: 40px;
  margin: 0 auto;
}

.pentagon {
  position: relative;
  width: 342px;
  transform: rotate(90deg);
  height: 425px;
  background: var(--greenn-light);
  clip-path: polygon(50% 0, 100% 54%, 100% 100%, 0% 100%, 0 54%);
}

.triangle {
  position: relative;
  background-color: var(--greenn-light);
  text-align: left;
  width: 100%;
}
.triangle:before,
.triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width: 15em;
  height: 15em;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(270deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;

  margin-bottom: 0 !important;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}

.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}

.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}

.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indicadores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  .indicador {
    background: #ffffff;
    border: 0.5px solid #ededf0;
    border-radius: 10px;
    height: 145px;
    padding: 25px 35px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: var(--greenn);
      margin-bottom: 12px;
    }
    h2 {
      font-weight: 600;
      font-size: 20px;
      color: var(--gray01);
    }
  }
  .flex-values {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 400;
      font-size: 16px;
      color: var(--gray01);
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .indicadores {
    grid-template-columns: 1fr;
  }
}
</style>
